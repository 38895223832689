import React from 'react'
import { Box, Text } from 'grommet'

import Layout from '../components/layout'
import Heading from '../components/Heading'
import SocialLinks from '../components/SocialLinks'
import SEO from '../components/seo'
import ConstrainedContainer from '../components/ConstrainedContainer'

const ThankYou = () => (
  <Layout>
    <SEO title="Thank You" />
    <Box pad={{ vertical: 'large' }}>
      <Heading level={2} textAlign="center" alignSelf="center">
        Thank You
      </Heading>
      <ConstrainedContainer inner>
        <Text>One of our team members will be in contact with you soon.</Text>
        <Text textAlign="center" margin="medium">
          Connect with us.
        </Text>
        <SocialLinks />
      </ConstrainedContainer>
    </Box>
  </Layout>
)

export default ThankYou
