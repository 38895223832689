import React from 'react'
import SmartLink from './SmartLink'
import { Box } from 'grommet'
import { Facebook, Instagram, Linkedin } from 'grommet-icons'

const SocialLinks = () => (
  <Box
    direction="row"
    fill="horizontal"
    justify="center"
    gap="small"
    width="medium"
  >
    <SmartLink to="https://www.facebook.com/ModernMailco/">
      <Facebook size="medium" />
    </SmartLink>
    <SmartLink to="https://www.instagram.com/modernmail/">
      <Instagram size="medium" />
    </SmartLink>
    <SmartLink to="https://www.linkedin.com/company/modern-mail-co">
      <Linkedin size="medium" />
    </SmartLink>
  </Box>
)

export default SocialLinks
